<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{msg}}</h1>          
        </div>
      </div>
    </div>

  </div>
</template>
<script>
//import { isLoggedIn} from '../variables';
export default {
  name: 'EventSingle',
  data () {
    return {
      msg: this.$route.params.msg
    }    
  }
  ,
  created() {
      
  }
}
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
</style>